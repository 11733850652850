import ArticleIcon from "@/assets/icons/article.svg";
import CalendarIcon from "@/assets/icons/calendar_today.svg";
import DoctorIcon from "@/assets/icons/doctor.svg";
import PersonIcon from "@/assets/icons/person.svg";
import NewLayout from "@/components/common/NewLayout";
import { NetworkManager } from "@/network-module/NetworkManager";
import dayjs from "dayjs";
import "dayjs/locale/ko";
dayjs.locale("ko");

import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  COLORS,
  ContentTitle,
  Title,
  typography_style_heading_md_medium,
} from "vd-component";

const ReceptionSelectPrev = ({
  nextStep,
  hpId,
  backFunc,
}: {
  nextStep: Function;
  hpId: number | string;
  backFunc: Function;
}) => {
  const navigate = useNavigate();
  const [prevData, setPrevData] = useState<any>(null);

  useEffect(() => {
    NetworkManager.hospitalService.getThirtyDaysFetch(hpId).then((res) => {
      setPrevData(res);
    });
  }, []);

  useEffect(() => {
    if (prevData != null && prevData.length < 1) {
      nextStep(null);
    }
  }, [prevData]);

  if (prevData == null) {
    return <></>;
  }

  if (prevData != null && prevData.length > 0) {
    const lastVisitDate = dayjs(prevData[0]?.receiptStartDt ?? "").format(
      "M월 DD일",
    );
    const RECEPTION_INFO_LIST = [
      {
        title: "접수일시",
        subTitle: dayjs(prevData[0]?.receiptStartDt ?? "").format(
          "YY.MM.DD (ddd)",
        ),
        icon: CalendarIcon,
      },
      {
        title: "담당의사",
        subTitle: prevData[0]?.doctorName ?? "",
        icon: DoctorIcon,
      },
      {
        title: "진료대상",
        subTitle: prevData[0]?.hospitalPatientName ?? "",
        icon: PersonIcon,
      },
      {
        title: "주증상",
        subTitle: prevData[0]?.cc ?? "",
        icon: ArticleIcon,
      },
    ];
    return (
      <NewLayout
        backFunc={backFunc}
        css={{
          gap: "24px",
          flexDirection: "column",
          padding: "49px 140px",
        }}
      >
        <Title
          size="2x-large"
          title="지난 방문일이 {0} 입니다<br />같은 주 증상으로 진료를 보시겠어요?"
          highlightArr={[lastVisitDate]}
          description="자세한 진료 내역은 브이닥 앱을 통해 확인 하실 수 있습니다."
          descriptionStyleProps={{ color: COLORS.colorLabelLight }}
          css={{ alignItems: "flex-start" }}
        />
        <div
          css={{
            display: "grid",
            gridTemplateColumns: "2fr 2fr",
            padding: "24px 32px",
            rowGap: "8px",
            background: COLORS.colorBackgroundLight,
            borderRadius: "8px",
            justifyItems: "flex-start",
          }}
        >
          {RECEPTION_INFO_LIST.map((list) => {
            return (
              <ContentTitle
                key={list.title}
                variants="text"
                title={list.title}
                subTitle={
                  <span
                    css={[
                      typography_style_heading_md_medium,
                      {
                        whiteSpace: "pre-wrap",
                        margin: "0",
                        display: "-webkit-box",
                        WebkitLineClamp: 1,
                        WebkitBoxOrient: "vertical",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      },
                    ]}
                  >
                    {list.subTitle}
                  </span>
                }
                labelProps={{
                  size: "large",
                  variant: "on",
                  leftIcon: (
                    <div
                      css={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: 24,
                        height: 24,
                      }}
                    >
                      <img src={list.icon} alt="ICON" />
                    </div>
                  ),
                  css: {
                    minWidth: "104px",
                    justifyContent: "normal",
                  },
                }}
              />
            );
          })}
        </div>
        <div
          css={{
            display: "flex",
            alignItems: "center",
            gap: 12,
            width: "100%",
          }}
        >
          <Button
            variant="secondary-line"
            size="x-large"
            css={{ width: "100%" }}
            onClick={() => nextStep(null)}
          >
            아니오, 다른 증상으로 볼게요
          </Button>
          <Button
            variant="primary"
            size="x-large"
            css={{ width: "100%" }}
            onClick={() => nextStep(prevData[0].preClinicRecordId)}
          >
            네, 같은 증상으로 볼게요
          </Button>
        </div>
      </NewLayout>
    );
  } else {
    return <></>;
  }
};
export default ReceptionSelectPrev;
