import { HighLight } from "@/components/common/HighLight";
import { t } from "i18next";
import {
  Button,
  Popup,
  typography_style_heading_lg_semibold,
} from "vd-component";

const ReceptionValidPopup = ({
  validateModalOpen,
  setValidateModalOpen,
  errorList,
}: {
  validateModalOpen: boolean;
  setValidateModalOpen: (value: boolean) => void;
  errorList: Array<string>;
}) => {
  return (
    <Popup
      isOpen={validateModalOpen}
      onClose={() => {
        setValidateModalOpen(false);
      }}
      css={{
        padding: "24px 20px",
        gap: "32px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        height: "45%",
      }}
    >
      <div
        css={[
          typography_style_heading_lg_semibold,
          {
            display: "flex",
            flexDirection: "column",
            textAlign: "center",
            height: "100%",
            justifyContent: "center",
          },
        ]}
      >
        {errorList.map((v, k) => {
          return <HighLight key={k} text={v} textColor="black" />;
        })}
      </div>
      <div>
        <Button
          onClick={() => {
            setValidateModalOpen(false);
          }}
          size="large"
          variant="primary"
          css={{ width: "100%" }}
        >
          {t("common.confirm")}
        </Button>
      </div>
    </Popup>
  );
};

export default ReceptionValidPopup;
