import StorageManager from "@/common/services/StorageManager";
import TermContentModal from "@/features/Reception/components/TermContentModal";
import { NetworkManager } from "@/network-module/NetworkManager";
import { TermEntity, TermState } from "@/network-module/entities/TermEntity";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Checkbox,
  COLORS,
  Title,
  typography_style_heading_md_semilbold,
  typography_style_heading_sm_medium,
} from "vd-component";
import NewLayout from "../common/NewLayout";

const TermAgreePage = ({
  nextStep,
  backFunc,
}: {
  nextStep: Function;
  backFunc: Function;
}) => {
  const navigate = useNavigate();

  const [selectedTermId, setSelectedTermId] = useState<number>(0);
  const hospitalId = StorageManager.getDataNow("hospitalId");
  const { data: termSummaryList } =
    NetworkManager.termService.getTermSummaryList(hospitalId);

  const [allChecked, setAllChecked] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [termState, setTermState] = useState<TermState[]>([]);

  useEffect(() => {
    setTermState(() => {
      return termSummaryList.map((v: TermEntity) => {
        return {
          id: v.id,
          required: v.required,
          title: v.title,
          checked: false,
        };
      });
    });
  }, [termSummaryList]);

  const makeTermsId = () => {
    return termState.map((v, k) => {
      if (v.checked) {
        return v.id;
      }
    });
  };

  const CheckAllTerms = (allChecked: boolean) => {
    let tmpTerms;
    if (allChecked) {
      tmpTerms = termState.map((v, k) => {
        v.checked = true;
        return v;
      });
      setTermState(tmpTerms);
      return;
    }
    tmpTerms = termState.map((v, k) => {
      v.checked = false;
      return v;
    });
  };

  const ChangeTermState = (id: number) => {
    let state = termState.map((term) =>
      term.id === id ? { ...term, checked: !term.checked } : term,
    );
    var allCheck = true;
    state.forEach((data) => {
      if (data.checked == false) allCheck = false;
    });
    setTermState(state);
    setAllChecked(allCheck);
  };

  const CheckTermValidation = () => {
    let requiredChecked = true;
    termState.forEach((term, k) => {
      if (term.required && term.checked == false) {
        requiredChecked = false;
      }
    });
    return requiredChecked;
  };

  useEffect(() => {
    if (selectedTermId != 0) {
      setIsModalOpen(true);
    }
  }, [selectedTermId]);

  return (
    <NewLayout
      backFunc={backFunc}
      css={{
        width: "100%",
        height: "100%",
        display: "flex",
        gap: "24px",
        flexDirection: "column",
        padding: "35px 140px",
        // background: COLORS.colorBackgroundLight,
      }}
    >
      {/* <div
        style={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
          padding: "35px 140px",
          background: COLORS.colorBackgroundWhite,
          borderRadius: "12px",
          gap: "20px",
          boxShadow:
            "0 0 2px 0 rgba(0, 0, 0, 0.08), 0 2px 4px 0 rgba(0, 0, 0, 0.08), 0 2px 8px 0 rgba(0, 0, 0, 0.12)",
        }}
      > */}
      <Title
        size="x-large"
        title="안전하고 편리한 서비스 제공을 위해<br />
아래의 {0}가 필요합니다."
        highlightArr={["이용약관에 대한 동의"]}
      />

      <div
        css={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          minHeight: "223px",
        }}
      >
        <Checkbox
          size="large"
          checked={allChecked}
          direction="back"
          isArrowIcon={false}
          css={{
            paddingBottom: "12px",
            width: "100%",
            borderBottom: "1px solid",
            borderColor: COLORS.colorLineNomal,
          }}
          checkboxStyles={{ width: "32px", height: "32px" }}
          handleCheckbox={() => {
            CheckAllTerms(!allChecked);
            setAllChecked(!allChecked);
          }}
        >
          <span
            css={[
              typography_style_heading_md_semilbold,
              { color: COLORS.colorLabelAssistive },
            ]}
          >
            모두 동의하기
          </span>
        </Checkbox>
        {termState.map((term, idx) => {
          return (
            <Checkbox
              key={term.id}
              size="large"
              checked={termState[idx]?.checked}
              direction="back"
              css={{
                width: "100%",
                padding: idx === 0 ? "8px 0 4px" : "4px 0",
              }}
              checkboxStyles={{ width: "32px", height: "32px" }}
              arrowIconStyles={{ width: "24px", height: "24px" }}
              labelIconStyles={{ gap: "4px" }}
              handleArrowIcon={() => {
                setSelectedTermId(termState[idx].id);
              }}
              handleCheckbox={() => {
                ChangeTermState(termState[idx].id);
              }}
            >
              <span
                css={[
                  typography_style_heading_sm_medium,
                  { color: COLORS.colorLabelAssistive },
                ]}
              >
                {term.title}
              </span>
            </Checkbox>
          );
        })}
      </div>

      <div css={{ display: "flex", gap: "8px", width: "100%" }}>
        <Button
          variant="secondary-line"
          size="x-large"
          css={{ width: "100%", height: "72px" }}
          onClick={() => {
            navigate("/");
          }}
        >
          취소
        </Button>
        <Button
          size="x-large"
          css={{ width: "100%", height: "72px" }}
          disabled={!CheckTermValidation()}
          onClick={() => {
            if (CheckTermValidation()) {
              nextStep(makeTermsId());
            }
          }}
        >
          동의하고 가입
        </Button>
      </div>
      {/* </div> */}

      <TermContentModal
        onClose={() => {
          setSelectedTermId(0);
        }}
        termId={selectedTermId}
        isModalOpen={isModalOpen}
      />
    </NewLayout>
  );
};

export default TermAgreePage;
